'use client';

import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as env from '../../shared/lib/env';
import { Button } from '../../shared/ui/Button';
import If from '../../shared/ui/If';
import TextField from '../../shared/ui/TextField';
import Trans from '../../shared/ui/Trans';
import useSignInWithEmailPassword from '../hooks/use-sign-in-with-email-password';

const EmailPasswordSignInForm: React.FCC = () => {
  const { t } = useTranslation();
  const signInMutation = useSignInWithEmailPassword();
  const loading = signInMutation.isPending;

  const onSubmit = (credentials: { email: string; password: string }) => {
    signInMutation.mutate(credentials);
  };

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const emailControl = register('email', { required: true });
  const passwordControl = register('password', { required: true });

  return (
    <form
      className={'w-full'}
      onSubmit={handleSubmit(onSubmit)}
      data-cy="auth-sign-in-form"
    >
      <div className={'flex-col space-y-5'}>
        <TextField>
          <TextField.Label>
            <Trans i18nKey={'common:emailAddress'} />
            <TextField.Input
              data-cy="email-input"
              required
              type="email"
              placeholder={t('common:emailAddress')}
              {...emailControl}
            />
          </TextField.Label>
          <TextField.Hint>
            <Trans i18nKey={'auth:emailSignInHint'} />
          </TextField.Hint>
        </TextField>

        <TextField>
          <TextField.Label>
            <Trans i18nKey={'common:password'} />

            <TextField.Input
              data-cy="password-input"
              required
              type="password"
              placeholder={t('common:password')}
              {...passwordControl}
            />
          </TextField.Label>
        </TextField>
      </div>

      <div className="pt-14">
        <Button
          className={'w-full'}
          type="submit"
          loading={loading}
          data-cy="auth-submit-button"
        >
          <If condition={loading} fallback={<Trans i18nKey={'auth:signIn'} />}>
            <Trans i18nKey={'auth:signingIn'} />
          </If>
        </Button>
      </div>

      <div className={'text-xs ml-auto pt-4'}>
        <Link
          href={`${
            env.managerAppUrl
          }/passwordreset?returnUrl=${env.getHostURL()}`}
          className="hover:underline font-semibold underline text-sm text-gray-700"
          target="_blank"
          data-cy="forgot-password-link"
        >
          <Trans i18nKey={'auth:passwordForgottenQuestion'} />
        </Link>
      </div>
    </form>
  );
};

export default EmailPasswordSignInForm;
