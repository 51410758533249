import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  firstAssitantMessage,
  isLastMessageErrorAtom,
  isLoadingAtom,
  messagesAtom,
  setAssistantMessageAsError,
  setMessagesAtom,
  updateLastAssistantMessageAtom,
  updateMessageFeedbackByIdAtom,
  updateMessagesAtom,
} from './atom';
import { Message } from './types';

export function useMessageById(id: string) {
  const updateMessageFeedback = useSetAtom(updateMessageFeedbackByIdAtom);
  const message = useAtomValue(messagesAtom).find((m) => m.id === id);
  return {
    message,
    updateMessageFeedback: (feedback: Message['feedback']) =>
      updateMessageFeedback({ id, feedback }),
  };
}

export function useAssistantMessage() {
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const updateLastMessage = useSetAtom(updateLastAssistantMessageAtom);
  const setError = useSetAtom(setAssistantMessageAsError);

  return { isLoading, setIsLoading, updateLastMessage, setError };
}

export function useIsLastMessageError() {
  return useAtomValue(isLastMessageErrorAtom);
}

export function useMessages() {
  const data = useAtomValue(messagesAtom);
  const update = useSetAtom(updateMessagesAtom);
  const set = useSetAtom(setMessagesAtom);
  const assistantMessage = useAtomValue(firstAssitantMessage);

  return { data, update, set, chatId: assistantMessage?.chatId };
}

export function useClearMessages() {
  const setMessages = useSetAtom(messagesAtom);

  return () => setMessages([]);
}
