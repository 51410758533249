export class RequestError extends Error {
  status: number;
  info: Record<string, unknown> & {
    message: string;
  };

  constructor(message: string) {
    super();
    this.message = message;
    this.status = 0;
    this.info = {
      message: message,
    };
  }
}
