import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { toast } from 'sonner';

import useApiRequest from '../../shared/hooks/use-api-request';
import { useTranslation } from 'react-i18next';
import { useClearMessages } from '../../shared/store/messages/hooks';
import { UrlPaths } from '../../shared/lib/constants';
import { RequestError } from '../../shared/lib/errors/request-error';
import HttpStatusCode from '../../shared/lib/http-status-code.enum';

interface Credentials {
  email: string;
  password: string;
}

function useSignInWithEmailPassword() {
  const clearMessages = useClearMessages();
  const { t } = useTranslation();
  const router = useRouter();
  const apiRequest = useApiRequest('/api/auth/login');

  return useMutation({
    mutationFn: (credentials: Credentials) => {
      return apiRequest({
        username: credentials.email,
        password: credentials.password,
      }).then(async (res) => {
        if (!res.ok) {
          const error = new RequestError(res.statusText);

          error.status = res.status;
          error.info = await res.json();

          throw error;
        }

        return await res.json();
      });
    },
    onSuccess: () => {
      clearMessages();
      router.push(UrlPaths.HOME);
    },
    onError: (error: RequestError) => {
      const message =
        error.status === HttpStatusCode.Unauthorized
          ? t('auth:invalidCredentials')
          : t('auth:signInError');

      toast.error(error.info.message || message, {
        id: 'auth-error-message',
      });
    },
  });
}

export default useSignInWithEmailPassword;
