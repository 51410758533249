import { atom, Atom } from 'jotai';
import {
  ChatBotMessageRole,
  Message,
  Messages,
  MessageState,
  MessageType,
} from './types';

export const messagesAtom = atom<Messages>([]);
export const errorAtom = atom<Error | string | null>(null);
export const isLoadingAtom = atom<boolean>(false);

export const updateMessagesAtom = atom(null, (get, set, update: Message) => {
  const messages = get(messagesAtom);

  set(messagesAtom, [...messages, update]);
});

export const setMessagesAtom = atom(null, (get, set, messages: Message[]) => {
  set(messagesAtom, messages);
});

export const setAssistantMessageAsError = atom(null, (get, set) => {
  const messages = get(messagesAtom);

  if (messages.at(-1)?.role === ChatBotMessageRole.Assistant) {
    const updatedLastMessage = {
      ...messages[messages.length - 1],
      state: MessageState.Error,
    };
    const updatedMessages = [...messages.slice(0, -1), updatedLastMessage];

    set(messagesAtom, updatedMessages);
  }
});

export const updateMessageFeedbackByIdAtom = atom(
  null,
  (
    get,
    set,
    { id, feedback }: { id: string; feedback: Message['feedback'] }
  ) => {
    const messages = get(messagesAtom);
    const messageIndex = messages.findIndex((message) => message.id === id);

    if (messageIndex === -1) return;

    const updatedMessage = {
      ...messages[messageIndex],
      feedback,
    };

    const updatedMessages = [
      ...messages.slice(0, messageIndex),
      updatedMessage,
      ...messages.slice(messageIndex + 1),
    ];

    set(messagesAtom, updatedMessages);
  }
);

export const updateLastAssistantMessageAtom = atom(
  null,
  (get, set, update: Message) => {
    const messages = get(messagesAtom);

    if (messages.at(-1)?.role === ChatBotMessageRole.Assistant) {
      const updatedLastMessage = {
        ...messages[messages.length - 1],
        ...update,
      };
      const updatedMessages = [...messages.slice(0, -1), updatedLastMessage];

      set(messagesAtom, updatedMessages);
    } else {
      set(messagesAtom, [...messages, update]);
    }
  }
);

export const isLastMessageErrorAtom: Atom<boolean> = atom((get) => {
  const messages = get(messagesAtom);

  if (messages.length === 0) return false;

  const lastMessage = messages[messages.length - 1];

  return lastMessage.type === MessageType.Error;
});

export const firstAssitantMessage = atom((get) => {
  const messages = get(messagesAtom);

  return messages.find(
    (message) => message.role === ChatBotMessageRole.Assistant
  );
});
